import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import SEO from "../components/seo"
import Layout from "../components/layout"
import Hero from "../components/hero"
import RichText from "../components/richText"
import Form from "../components/form"

export const query = graphql`
  query {
    sanityContact {
      _rawHero
      _rawBody(resolveReferences: { maxDepth: 10 })
      form
      seo {
        metaTitle
        canonical
        description
        noIndex
        openGraphImage {
          asset {
            url
          }
        }
      }
    }
  }
`

const ContactTemplate = props => {
  const page = props.data.sanityContact
  const customTitle = page.seo && page.seo.metaTitle ? page.seo.metaTitle : null
  const description =
    page.seo && page.seo.description ? page.seo.description : null
  const image =
    page.seo &&
    page.seo.openGraphImage &&
    page.seo.openGraphImage.asset !== null
      ? page.seo.openGraphImage.asset.url
      : null
  const title = "Contact Us"
  return (
    <Layout theme="light">
      <Helmet>
        {page.seo && page.seo.noIndex && (
          <meta name="robots" content="noindex" />
        )}
      </Helmet>
      <SEO
        title={title}
        customTitle={customTitle}
        description={description}
        image={image}
      />
      <Hero {...page._rawHero} theme="light" graph="bar" />
      <div className="grid">
        <div
          className="gridItem large-6 xlarge-5 xlarge-offset-1"
          style={{ marginBottom: `32px` }}
        >
          <RichText blocks={page._rawBody} />
        </div>
        <div className="gridItem large-6 xlarge-5">
          <Form
            frameTitle={`${title} form`}
            height={"1200px"}
            formUrl={page.form}
          />
        </div>
      </div>
    </Layout>
  )
}

export default ContactTemplate
